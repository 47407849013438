import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Git force

// Elements
import ParseContent from 'components/shared/ParseContent'
import BreadcrumbProvider from 'components/elements/Misc/Breadcrumb'

const Image = styled(Plaatjie)`
  max-width: 2000px;
  height: 500px;
  @media (max-width: 575px) {
    height: 350px;
  }
`

const Content = styled(ParseContent)`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey};
`

const Block = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 768px) {
    margin-top: -9.5rem;
  }
  @media (max-width: 767px) {
    margin-top: -5.5rem;
  }
  @media (max-width: 575px) {
    margin-top: -2.5rem;
  }
`

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const BannerDefault: React.FC<BannerDefaultProps> = ({
  pageContext,
  fields,
}) => (
  <section className="mb-5">
    <div className="d-flex justify-content-center">
      <Image alt="banner" image={fields?.image} />
    </div>
    <div className="container h-100">
      <div className="row">
        <div className="col-xl-5">
          <Block className="px-md-5 py-md-4 px-3 py-2">
            <Content content={fields.description} className="p-3" />
            <BreadcrumbProvider pageContext={pageContext} />
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default BannerDefault

import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

interface BannerLandingProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerWrapper = styled.div`
  position: relative;

  @media (min-width: 576px) {
    height: 72vh;
    min-height: 720px;
    max-height: 980px;
  }
  @media (max-width: 575px) {
    height: 550px;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    line-height: 59px !important;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    @media (min-width: 576px) {
      font-size: 46px !important;
    }
    @media (max-width: 575px) {
      font-size: 36px !important;
      line-height: 49px !important;
    }
  }
`

const MenuWrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 576px) {
    margin-top: -14rem;
  }
  @media (max-width: 575px) {
    margin-top: -5rem;
  }
`

const Menu = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & a {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`

const Project = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const MenuTitle = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 28px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }
`

const MenuLink = styled(NavLink)`
  padding: 10px 0;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const InnerWrapper = styled.div`
  @media (min-width: 1400px) {
    min-width: 200px;
  }
`

const LinkWrapper = styled.div`
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: unset;
  }
`

const SmallArrow = styled.div`
  & svg {
    width: 15px;
    height: 10px;
  }
`

const BannerLanding: React.FC<BannerLandingProps> = ({ fields }) => {
  const [rightIndex, setRightIndex] = useState<number | null>(null)
  const [leftIndex, setLeftIndex] = useState<number | null>(null)

  return (
    <section className="mb-5">
      <BannerWrapper>
        <Image alt="banner" image={fields?.image} />
        <div className="container h-100 py-5">
          <div className="row h-100 justify-content-center">
            <div className="col-lg-10 d-flex align-items-center">
              <Content
                content={fields.description}
                className="mb-sm-5 pb-sm-5"
              />
            </div>
          </div>
        </div>
      </BannerWrapper>
      {fields.subtype === 'menu' && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <MenuWrapper className="row">
                <Menu className="col-lg-7">
                  <div className="d-flex justify-content-around p-sm-4 py-4">
                    <InnerWrapper>
                      <MenuTitle content={fields.titleleft} />
                      {fields.menuleft?.map((item, index: number) => {
                        const hovered: boolean = leftIndex === index

                        return (
                          <LinkWrapper className="position-relative me-3 me-sm-0">
                            <motion.div
                              className="d-inline-flex align-items-center"
                              onHoverStart={() => setLeftIndex(index)}
                              onHoverEnd={() => setLeftIndex(null)}
                            >
                              {hovered && (
                                <SmallArrow className="me-2">
                                  <ArrowRight />
                                </SmallArrow>
                              )}
                              <MenuLink
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                to={item?.link?.url || '/'}
                              >
                                {item?.link?.title}
                              </MenuLink>
                            </motion.div>
                          </LinkWrapper>
                        )
                      })}
                    </InnerWrapper>
                    <InnerWrapper className="me-lg-5">
                      <MenuTitle content={fields.titleright} />
                      {fields.menuright?.map((item, index: number) => {
                        const hovered: boolean = rightIndex === index

                        return (
                          <LinkWrapper className="position-relative">
                            <div
                              className="d-inline-flex align-items-center"
                              onMouseEnter={() => setRightIndex(index)}
                              onMouseLeave={() => setRightIndex(null)}
                            >
                              {hovered && (
                                <SmallArrow className="me-2">
                                  <ArrowRight />
                                </SmallArrow>
                              )}
                              <MenuLink
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                to={item?.link?.url || '/'}
                              >
                                {item?.link?.title}
                              </MenuLink>
                            </div>
                          </LinkWrapper>
                        )
                      })}
                    </InnerWrapper>
                  </div>
                </Menu>
                <Project className="col-lg-5">
                  <HighlightedProject fields={fields} />
                </Project>
              </MenuWrapper>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

interface HighlightedProjectProps {
  // eslint-disable-next-line
  fields?: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const HighlightedProject: React.FC<HighlightedProjectProps> = ({ fields }) => {
  const {
    allWpRealestate,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedRealestateQueryQuery>(graphql`
    query highlightedRealestateQuery {
      allWpRealestate(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalRealestateFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpRealestate.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Realestate"
      showIds={fields?.relationrealestate?.map(
        ({ databaseId }: any) => databaseId
      )}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <React.Fragment key={post.node.id}>
            <BlogGridPost node={node} />
          </React.Fragment>
        )
      })}
    </>
  )
}

const Post = styled(NavLink)``

const PostInner = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 18px;
  }

  & h3 {
    font-family: ${({ theme }) => theme.font.family.primaryBold};
  }

  & p {
    color: ${({ theme }) => theme.color.light};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }

    @media (max-width: 575px) {
      -webkit-line-clamp: 3;
    }
  }
`

const ArrowWrapper = styled.div`
  right: 5px;

  & svg {
    width: 25px;
    height: 20px;
  }

  @media (min-width: 576px) {
    bottom: 5px;
  }

  @media (max-width: 575px) {
    bottom: 12px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    realestatedetail: {
      shortdescription: string
    }
    projectcategories: {
      nodes: any
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [isHover, setHover] = useState<boolean>(false)

  const category = 'Vastgoedontwikkeling'

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      className="h-100"
    >
      <Post to={node.uri} className="d-flex h-100 p-sm-4 p-1 w-100">
        <PostInner className="position-relative w-100">
          <h3 className="pt-4 pt-sm-0 mt-2">{category}</h3>
          <h2>{node.title}</h2>
          {node.realestatedetail.shortdescription && (
            <ParseContent
              content={node.realestatedetail.shortdescription}
              className="pb-5 pb-md-0"
            />
          )}

          <ArrowWrapper className="position-absolute">
            <motion.div animate={isHover ? { x: 20 } : { x: 0 }}>
              <ArrowRight />
            </motion.div>
          </ArrowWrapper>
        </PostInner>
      </Post>
    </motion.div>
  )
}

export default BannerLanding
